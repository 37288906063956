<template>
    <div class="">
        <section class="rounded-md bg-white print:shadow-none">
            <!-- Header section with buttons -->
            <div class="flex items-center justify-between px-4 py-2 sm:px-6 border-b print:hidden">
                <!-- Left buttons -->
                <div class="flex items-center gap-4">
                    <button @click="toggleCollapse('collapse')" class="text-red-800">Collapse All</button>
                    <button @click="toggleCollapse('show')" class="text-red-800">Show All</button>
                </div>

                <!-- Right buttons -->
                <ul class="flex items-center justify-between text-gray-500">
                    <!-- Print Button -->
                    <li class="flex items-center">
                        <button @click="handlePrint" class="mx-4">
                            <!-- <outline-printer-icon class="h-5 w-5"></outline-printer-icon> -->
                        </button>
                    </li>

                    <!-- Export Button -->
                    <li class="flex items-center">
                        <button @click="handleExport" class="mx-4 flex items-center justify-center text-black font-bold">
                            <!-- Use the image or an SVG icon for Excel -->
                            <img src="https://upload.wikimedia.org/wikipedia/commons/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg"
                                class="h-4 w-4" width="20" height="20" />
                            <span class="ml-2">Export</span>
                        </button>
                    </li>
                </ul>
            </div>

            <!-- Content goes here -->
            <div class="print:block">
                <div class="px-4 py-9 sm:px-6 flex justify-center flex-col items-center">
                    <h1 class="text-2xl font-bold">Branch Report</h1>
                    <div class="flex mt-8 text-gray-600 gap-x-5">
                        <p>{{ new Date(fromDate).toDateString() }}</p>
                        <p>-</p>
                        <p>{{ new Date(toDate).toDateString() }}</p>
                    </div>
                </div>

                <!-- Content goes here -->
                <div class="border-y border-gray-200 mx-4 flex overflow-x-scroll bg-white">
                    <!-- ... -->
                    <div class="w-1/3 border border-gray-200 flex justify-center items-center">
                        <div class="p-2 font-semibold text-center">
                            Branch / Date
                        </div>
                    </div>
                    <div class="rounded-t-lg w-2/3">
                        <div class="flex justify-center p-2 border text-center border-gray-200">
                            <div class="font-semibold">TOTAL</div>
                        </div>
                        <div class="grid grid-cols-4 text-right text-sm border">
                            <div class="p-2 font-semibold border-r border-gray-200">Transaction</div>
                            <div class="p-2 font-semibold border-r border-gray-200">Sales</div>
                            <div class="p-2 font-semibold border-r border-gray-200">Commission</div>
                            <div class="p-2 font-semibold">Total</div>
                        </div>
                    </div>
                </div>

                <!-- More Content -->
                <!-- This part would loop through your reportInfo array and render content based on each item -->
                <div class="mx-4 py-3 overflow-x-scroll bg-white border-r border-l">
                    <div v-for="(data, index) in reportInfo" :key="index" class="mb-4 border-b border-gray-200">
                        <!-- ...rest of your content -->
                        <div class="mb-4 border-gray-200" :key="index">
                            <div class="flex items-center mx-4">
                                <button class="flex items-center font-semibold w-1/3"
                                    @click="resetActiveBranch(data.branch)">
                                    <template v-if="data.active">
                                        <i class='bx bx-chevron-down'></i>
                                    </template>
                                    <template v-else>
                                        <i class='bx bx-chevron-up'></i>
                                    </template>
                                    <span class="ml-2">
                                        {{ data.branch }}
                                    </span>
                                </button>
                                <template v-if="!data.active">
                                    <div class="w-2/3 grid grid-cols-4 text-right text-sm">
                                        <div class="p-2">
                                            {{ calculateMisc(data.transactions, 'total_transactions') }}
                                        </div>
                                        <div class="p-2">
                                            D {{ formatAmount(calculateMisc(data.transactions, 'total_sales')) }}
                                        </div>
                                        <div class="p-2">
                                            D {{ formatAmount(calculateMisc(data.transactions, 'total_commission')) }}
                                        </div>
                                        <div class="p-2">
                                            D {{ formatAmount(calculateMisc(data.transactions, 'total_sales') +
                                                calculateMisc(data.transactions, 'total_commission')) }}
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div v-if="data.active">
                                <div class="flex" v-for="(transaction, idx) in data.transactions" :key="idx">
                                    <div class="w-1/3 h-5 text-sm">
                                        <div class="p-2 ml-4">
                                            {{ new Date(transaction.date).toDateString() }}
                                        </div>
                                    </div>
                                    <div class="w-2/3 grid grid-cols-4 text-right text-sm">
                                        <div class="p-2">
                                            {{ transaction.total_transactions }}
                                        </div>
                                        <div class="p-2">
                                            D {{ formatAmount(transaction.total_sales) }}
                                        </div>
                                        <div class="p-2">
                                            D {{ formatAmount(transaction.total_commission) }}
                                        </div>
                                        <div class="p-2">
                                            D {{ formatAmount(parseInt(transaction.total_sales) +
                                                parseInt(transaction.total_commission)) }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="data.active" class="flex font-bold border-t">
                                <div class="w-1/3 h-5 text-sm">
                                    <div class="p-2 ml-4">TOTAL</div>
                                </div>
                                <div class="w-2/3 grid grid-cols-4 text-right text-sm">
                                    <div class="p-2">
                                        {{ calculateMisc(data.transactions, 'total_transactions') }}
                                    </div>
                                    <div class="p-2">
                                        D {{ formatAmount(calculateMisc(data.transactions, 'total_sales')) }}
                                    </div>
                                    <div class="p-2">
                                        D {{ formatAmount(calculateMisc(data.transactions, 'total_commission')) }}
                                    </div>
                                    <div class="p-2">
                                        D {{ formatAmount(calculateMisc(data.transactions, 'total_sales') +
                                            calculateMisc(data.transactions, 'total_commission')) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex font-bold bg-gray-200">
                        <div class="w-1/3 h-5 text-sm">
                            <div class=" p-2 ml-4">GRAND TOTAL</div>
                        </div>

                        <div class="w-2/3  grid grid-cols-4 text-right text-sm">
                            <div class=" p-2">{{ calculateTotal('total_transactions') }}</div>
                            <div class="p-2">D {{ formatAmount(calculateTotal('total_sales')) }}</div>
                            <div class="p-2">D {{ formatAmount(calculateTotal('total_commission')) }}</div>
                            <div class="p-2">D {{ formatAmount(calculateTotal('grand_total')) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ... -->
        </section>
    </div>
</template>

<script>
// import { ChevronDownIcon, ChevronUpIcon } from '@vue-hero-icons/solid'
import axios from "axios";
// import { saveAs } from 'file-saver'

export default {
    props: ["resultData", "fromDate", "toDate"],

    components: {
        // ChevronDownIcon,
        // ChevronUpIcon,
        // Your other components
    },
    data() {
        return {
            reportInfo: [], // Replace this with your initial data
            form: {}, // Replace this with your initial form data
            reportType: '', // Replace as needed
            reportAccount: '' // Replace as needed

        }
    },
    mounted() {
        // Your mounted code
        this.saveReportInfo();
    },
    methods: {
        saveReportInfo() {
            const info = []

            Object.keys(this.resultData).forEach(key => {
                info.push({
                    branch: key,
                    active: false,
                    transactions: this.resultData[key],
                })
            })

            console.log("transactions", info);
            this.reportInfo = info;
        },
        calculateMisc(transactions, type) {
            return transactions?.reduce((acc, curr) => acc + parseInt(curr[type]), 0)
        },
        resetActiveBranch(branch) {
            this.reportInfo = this.reportInfo.map(data => {
                if (data.branch === branch) {
                    data.active = !data.active
                }
                return data
            })
        },
        calculateTotal(type) {
            if (type === 'grand_total') {
                return this.reportInfo.reduce((acc, curr) => {
                    return acc + this.calculateMisc(curr.transactions, 'total_sales') + this.calculateMisc(curr.transactions, 'total_commission')
                }, 0);
            } else {
                return this.reportInfo.reduce((acc, curr) => {
                    return acc + this.calculateMisc(curr.transactions, type)
                }, 0);
            }
        },
        toggleCollapse(type) {
            this.reportInfo = this.reportInfo.map(data => {
                data.active = type === 'collapse' ? false : true
                return data
            })
        },
        handlePrint() {
            window.print()
        },
        async handleExport() {
            axios({
                url: `/report/download?from=${this.fromDate}&to=${this.toDate}&superagent=all&agent=all&report=branch_sales&reportBy=&branch=&staff=&account_id=`,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                let blob = new Blob([response.data]);
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `branch-sales-${this.fromDate}-${this.toDate}.xlsx`;
                link.click();
            });
            // try {
            //     const response = await axios.get(
            //         `/api/report/download?from=${this.form.from}&to=${this.form.to}&superagent=all&agent=all&report=${this.reportType}&reportBy=&branch=${this.form.branch}&staff=${this.form.staff}&account_id=${this.reportAccount}`,
            //         { responseType: 'blob' }
            //     )
            //     const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            //     // Replace "saveAs" with your blob-saving logic
            // } catch (error) {
            //     console.error('This is the error from export to excel', error)
            // }
        },
        formatAmount(value) {
            let val = (value / 1).toFixed(2).replace(",", ".");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>

<style scoped>
/* your CSS code */
</style>
